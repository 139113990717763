import Division from '@/entities/Division.js';

const defaultForm = {
    id: null,
    name: '',
    phone: '',
    crm_manager_email: '',
    caller_id_account: true,
    logo: null,
    logoFrPath: '',
    logo_en: null,
    logoEnPath: '',
    province: 'QC',
    country: 'CA',
    timezone: 'America/Montreal',
    locale: 'fr',
    postal_code: '',
    address: '',
    city: '',

    recording_message_audio: null,
    recording_message_audio_file: null,
    recording_message_audio_outbound: null,
    recording_message_audio_outbound_file: null,

    ivr: false,
    ivr_name: '',
    ivr_locale: 'FR',
    ivr_service_number: '',
    ivr_waiting_sound: 'music',
    ivr_audio_welcome: null,
    ivr_audio_menu_fr: null,
    ivr_audio_menu_en: null,
    ivr_audio_invalid_fr: null,
    ivr_audio_invalid_en: null,
    ivr_audio_transfer_fr: null,
    ivr_audio_transfer_en: null,

    ivr_audio_welcome_file: null,
    ivr_audio_menu_fr_file: null,
    ivr_audio_menu_en_file: null,
    ivr_audio_invalid_fr_file: null,
    ivr_audio_invalid_en_file: null,
    ivr_audio_transfer_fr_file: null,
    ivr_audio_transfer_en_file: null,

    max_pause: 1,
    demo: false,
    untreated_lead: false,
    merge_rule: 'overwrite',
    import_rule: 'overwrite',
    default_monthly_mileage: 1000,

    phone_up: true,
    webboost: true,
    walk_in: true,
    leadxpress: true,
    loyalty: true,
    renewal: false,
    service: false,
    sale_table: false,
    campaign: false,
    event: false,
    web_order: true,

    auto_assign_associate: false,
    auto_assign_associate_delay: '3 months',
    restrict_lead_status_update_for_roles: [],
    association_flag_lead_duplicated: false,
    auto_renewal: false,
    auto_renewal_new: true,
    auto_renewal_used: false,
    display_approved_sources: false,
    disable_manual_duplicate: false,
    division_required: false,
    scan: false,
    in_turn: false,
    commercial: false,
    take_over_director: false,
    automation: false,
    equity_alert: false,
    duplicates: true,
    video_conference: false,
    vin_decoder: false,
    vin_manual_decoder: false,
    email_client: false,
    email_domain: '',
    access_all_leads_communications: false,
    enforced_mfa: false,
    round_robin: false,
    pause_round_robin_when_closed: false,
    check_availability_round_robin: false,
    source_mandatory: false,

    leadxpress_option: {
        reminder: {
            frequency: 15,
            recurrence: 0,
        },
        schedule: {
            account: true,
            advisor: false,
        },
        priority: 5,
        unassignUnansweredLead: false,
        timeLimitToAnswer: 900,
    },

    director_email_option: {
        new: {
            phone: 'cell',
            actived: false,
            director: null,
            frequency: 1,
        },
        used: {
            phone: 'cell',
            actived: false,
            director: null,
            frequency: 1,
        },
    },

    sale_table_options: {
        accessory_column: true,
        commercial_column: false,
        division_grouped_total_column: false,
        hide_profit: false,
    },

    waiting_sale_option: false,
    waiting_sale_date: null,

    custom_fields: [],

    client_card_fields: {
        wanted_vehicle: {
            tire: false,
            color_exterior: true,
            color_interior: true,
            fuel: false,
            weight: false,
            length: false,
            sleeping: false,
            order_number: false,
            offer_number: false,
            suffix: false,
            modality: true,
            end_contract_date: true,
            end_warranty_date: true,
            term: true,
            frequency: true,
            accessories: false,
            preparation: false,
            documentation: false,
            price: true,
            payment: true,
            payment_with_tax: false,
            rate: true,
            stock: true,
            profit: true,
            total: false,
            security_deposit: true,
            residual: true,
            allowed_mileage: true,
            warranty: true,
            category: false,
            category_rv: false,
            vehicle_state: false,
            budget: false,
            year: false,
            initial_cash: true,
            vin: true,
            transmission: true,
            comment: true,
            engine: false,
            bodyType: false,
            drivingWheels: false,
        },
        exchange_vehicle: {
            color_exterior: true,
            color_interior: true,
            vehicle_state: false,
            stock: true,
            value: true,
            requested: true,
            balance: true,
            end_contract_date: true,
            end_warranty_date: true,
            security_deposit: false,
            payment: true,
            payment_with_tax: false,
            residual: true,
            allowed_mileage: false,
            intention: true,
            renewal: false,
            refinance: false,
            vin: true,
            link: false,
            condition: true,
            mileage: true,
            mileage_excess: false,
            term: true,
            fuel: false,
            weight: false,
            length: false,
            sleeping: false,
            frequency: true,
            institution: true,
            sold_by: true,
            sold_date: true,
            warranty: true,
            modality: true,
            rate: true,
            category: false,
            category_rv: false,
            initial_cash: false,
            price: true,
            transmission: true,
            comment: true,
        },
        commercial: {
            exclude: false,
            meeting: false,
            profit: false,
        },
        performance: {
            qualification: true,
            walk_around: true,
            refinanced: true,
            deposit: false,
            twenty_four_hour: false,
            // New fields added for the custom account (some will also be shown on dealer account)
            phone_appointment: true,
            appointment: true,
            presented: true,
            sale_by_phone: true,
            sale: true,
            renew: true,
            road_test: true,
            take_over: true,
            be_back: true,
            write_up: false,
            long_term: false,
        },
        process: {
            available: false,
            deliverable: false,
            gas: false,
            recorded: false,
            paperwork: true,
            // New fields added for the custom account (some will also be shown on dealer account)
            lead_bank: true,
            progress_state: true,
            approved: true,
            delivery: true,
            waiting_sale: true,
            prepared: true,
            inspected: true,
            delivered: true,
            csi: true,
        },
        general_info: {
            communication_preference: true,
            sector: false,
            budget: false,
            referrer: true,
            keyword: true,
            search_term: true,
            navigation_history: false,
            do_not_market: false,
            // New fields added for the custom account (some will also be shown on dealer account)
            rating: true,
            lead_type: true,
            lead_form: true,
            created_method: true,
            source: true,
            division: true,
            comment: true,
            campaign: true,
            segment: true,
            storage: true,
            client_number: true,
            called_count: true,
            call_date: true,
            prepaid: true,
            loyalty: true,
            non_consumer_initiated: false,
            time_frame: false,
            web_url: true,
        },
    },

    business_hours_division_id: Division.NEW,
    business_hours: [
        {
            closing_time: '21:00:00',
            open: false,
            opening_time: '09:00:00',
            weekday: 1,
            division_id: Division.NEW,
        },
        {
            closing_time: '21:00:00',
            open: false,
            opening_time: '09:00:00',
            weekday: 2,
            division_id: Division.NEW,
        },
        {
            closing_time: '21:00:00',
            open: false,
            opening_time: '09:00:00',
            weekday: 3,
            division_id: Division.NEW,
        },
        {
            closing_time: '21:00:00',
            open: false,
            opening_time: '09:00:00',
            weekday: 4,
            division_id: Division.NEW,
        },
        {
            closing_time: '21:00:00',
            open: false,
            opening_time: '09:00:00',
            weekday: 5,
            division_id: Division.NEW,
        },
        {
            closing_time: '21:00:00',
            open: false,
            opening_time: '09:00:00',
            weekday: 6,
            division_id: Division.NEW,
        },
        {
            closing_time: '21:00:00',
            open: false,
            opening_time: '09:00:00',
            weekday: 7,
            division_id: Division.NEW,
        },
        {
            closing_time: '21:00:00',
            open: false,
            opening_time: '09:00:00',
            weekday: 1,
            division_id: Division.USED,
        },
        {
            closing_time: '21:00:00',
            open: false,
            opening_time: '09:00:00',
            weekday: 2,
            division_id: Division.USED,
        },
        {
            closing_time: '21:00:00',
            open: false,
            opening_time: '09:00:00',
            weekday: 3,
            division_id: Division.USED,
        },
        {
            closing_time: '21:00:00',
            open: false,
            opening_time: '09:00:00',
            weekday: 4,
            division_id: Division.USED,
        },
        {
            closing_time: '21:00:00',
            open: false,
            opening_time: '09:00:00',
            weekday: 5,
            division_id: Division.USED,
        },
        {
            closing_time: '21:00:00',
            open: false,
            opening_time: '09:00:00',
            weekday: 6,
            division_id: Division.USED,
        },
        {
            closing_time: '21:00:00',
            open: false,
            opening_time: '09:00:00',
            weekday: 7,
            division_id: Division.USED,
        },
        {
            closing_time: '21:00:00',
            open: false,
            opening_time: '09:00:00',
            weekday: 1,
            division_id: Division.SERVICE,
        },
        {
            closing_time: '21:00:00',
            open: false,
            opening_time: '09:00:00',
            weekday: 2,
            division_id: Division.SERVICE,
        },
        {
            closing_time: '21:00:00',
            open: false,
            opening_time: '09:00:00',
            weekday: 3,
            division_id: Division.SERVICE,
        },
        {
            closing_time: '21:00:00',
            open: false,
            opening_time: '09:00:00',
            weekday: 4,
            division_id: Division.SERVICE,
        },
        {
            closing_time: '21:00:00',
            open: false,
            opening_time: '09:00:00',
            weekday: 5,
            division_id: Division.SERVICE,
        },
        {
            closing_time: '21:00:00',
            open: false,
            opening_time: '09:00:00',
            weekday: 6,
            division_id: Division.SERVICE,
        },
        {
            closing_time: '21:00:00',
            open: false,
            opening_time: '09:00:00',
            weekday: 7,
            division_id: Division.SERVICE,
        },
    ],

    suppliers: [],
    outbound_suppliers: [],
    inbound_suppliers: [],
    teams: null,
    phone_providers: [],
    children: [],
    parents: [],

    alert_task_overlap: false,
    csi: false,
    csi_used: false,
    csi_task_delays: [],
    csi_used_task_delays: [],
    sale_by_phone: false,
    delivered_by: false,
    bdc_advisor: true,
    activity_report: true,
    sale_date_month: false,
    order_number: false,
    client_number: false,
    power_sport: false,
    recreative_special: false,
    calendar_options: true,
    in_turn_reset: true,
    sale_accessories: false,
    unsubscribe_email: true,
    unsubscribe_sms: true,
    unsubscribe_call: true,
    commercial_in_turn: false,
    desk_manager_in_turn: false,
    desking: false,
    dev_access: false,
    assigned_lead: false,
    gun_miss: false,
    in_turn_planned_meeting: false,
    in_turn_director_management: false,
    in_turn_be_back: false,
    leads_other_division: true,
    manually_status: true,
    limited_audio_access: false,
    unrestricted_assignment: false,
    secondary_advisor: false,
    verified_sale: false,
    allow_communications_when_verified_sale: false,
    auto_recorded_date: true,
    fiscal_year_date: null,

    mandatory_coordinate: false,
    vauto_fallback_email: '',
    twenty_four_hour: true,
    default_deliveryman_user_id: null,
    stock_required_for_sale: false,
    facebook_id: null,
    delivery_guest_group_id: null,

    mark_sold_lead_as_reached: true,

    active: false,
    activation_date: null,
    niotext: false,
    niotext_appointment: false,
    niotext_campaign: false,
    credit: true,
    trade_report: false,
    vehicle_text: false,
    vehicle_model_text: false,
    guest_action: false,
    analytics_workspace_id: null,
    is_imported_in_analytics: false,
    confirmation_appt_sale: false,
    confirmation_appt_service: false,
    restricted_notifications: false,
    automatch_incoming_call: false,
    delivery_timeframe: 'one_hour',

    service_products: [],

    blacklist_phone_up: [],
    sale_table_badge_active: false,
    sale_table_badge_field: null,
    delivery_guest_advisor: true,

    renewal_alert_options: {
        equity_base: null,
        end_location_notifications: [],
        end_warranty_notifications: [],
        excess_mileage_notifications: {
            delay: null,
            mileage_excess: null,
        },
        settings: [],
    },

    imap_ignored_domains: [],
    phone_provider: 'twilio',
    next_step_stopping: null,
    homepage: false,
    call_summary: false,
    call_summary_required: false,
};

export default {
    currentForm: defaultForm,
    defaultForm,
};
