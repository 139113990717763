export default {
    addInventoryVehicle: 'Ajouter un véhicule d\'inventaire',
    inventoryUnit: 'Véhicule d\'inventaire',
    filter: 'Filtre',
    changeInventoryUnit: 'Modifier l\'unité d\'inventaire',
    concurrentOpportunityDate: 'Créé il y a {0} jours',
    concurrenceOpportunityAssigne: 'Assigné à {0} {1}',
    concurrenceOpportunityUnAssigne: 'Non assigné',
    createdToday: 'Créé Aujourd\'hui',
    day: '{0} Jour|{0} Jours',
    emptyStatus: 'Aucun',
    notAvailable: 'Non Dispo.',
    make: 'Marque',
    year: 'Année',
    new: 'Neuf',
    used: 'Occasion',
    demo: 'Demo',
    fetchingError: 'Erreur lors de la récupération de l\'inventaire',
    stockStatusTBD: 'Statut à déterminer',
    searchingVehicles: 'Recherche de véhicules...',
    noStockFoundForFilter: 'Aucun véhicule trouvé pour un {0} {1} {2}.',
    noStockFoundForFilterWithStock: 'Aucun véhicule trouvé pour un {0} {1} {2} avec le stock {3}.',
    noStockFoundForFilterOnlyStock: 'Aucun véhicule trouvé pour le stock {0}.',
    noConcurrentOpportunity: 'Aucun lead avec ce véhicule.',
    inventoryFromSyncAccount: 'Ce lead provient d\'un autre compte.',
    emptyInventory: 'Aucun véhicule disponible dans l\'inventaire.',
    createLead: 'Créer lead',
    viewLead: 'Voir lead',
    select: 'Sélectionner',
    selected: 'Sélectionné',
    expend: 'Agrandir',
    searchPlaceholder: 'Rechercher marque, modèle, année, version, couleur, prix...',
    shrink: 'Réduire',
    selectedInOtherLeads: 'Sélectionné dans 1 lead | Sélectionné dans {0} leads',
    leadsWithThisVehicle: 'Leads avec ce véhicule',
    vehicles: 'Véhicules',
    vehicle: {
        bodyType: 'Carrosserie',
        certified: 'Certifié',
        comments: 'Commentaires',
        vdpUrl: 'URL du véhicule',
        demo: 'Demo',
        drivetrain: 'Motricité',
        fuel: 'Carburant',
        make: 'Marque',
        mileage: 'Odomètre',
        vin: 'Niv',
        price: 'Prix',
        engine: 'Moteur',
        options: 'Options',
        location: 'Emplacement',
        lot: 'Lot',
        transmission: 'Transmission',
        type: 'Type',
        warranty: 'Garantie',
        division: {
            new: 'Neuf',
            used: 'Occasion',
        },
        visibility: {
            active: 'Actif',
            archived: 'Archivé',
        },
        daysInInventory: 'Jours en inventaire',
        actualCost: 'Coût actuel',
    },
    boxes: {
        clickToCollapse: 'Cliquer pour réduire',
        clickToExpand: 'Cliquer pour agrandir',
    },
    column: {
        status: 'Statut',
        dmsStatus: 'Statut DMS',
        stock: 'Stock (# • @:inventory.vehicle.type)',
        year: 'Année',
        daysInInventory: 'Jours en Inv',
        model: 'Modèle',
        trim: 'Version',
        certified: 'Certifié',
        color: 'Couleur (Ext • Int)',
        exteriorColor: 'Couleur Ext.',
        interiorColor: 'Couleur Int.',
        price: 'Prix',
        leads: 'Leads',
        action: 'Actions',
        make: 'Marque',
        transmission: 'Transmission',
        odometer: 'Odomètre',
        concurrence: 'Concurrence',
        demo: 'Demo',
        fuel: 'Carburant',
        engine: 'Moteur',
        bodyType: 'Carrosserie',
        drivetrain: 'Motricité',
        archived: 'Visibilité',
        toArchived: 'Archiver',
        toUnarchived: 'Désarchiver',
        showOption: 'Afficher options',
    },
    statistics: {
        averagePrice: 'Prix Moyen',
        averageDaysInInventory: 'Jours Moyens en Inventaire',
        inStock: 'En stock',
        available: 'Disponible',
        unavailable: 'Indisponible',
        order: 'Commande',
        reserved: 'Réservé',
        locate: 'Localiser',
        invoice: 'Facturé',
        production: 'Production',
        hold: 'En attente',
        sold: 'Vendu',
        transit: 'En transit',
        new: 'Nouveau',
        demo: 'Demo',
        totalVehicles: 'Véhicules Totaux',
        lowestPrice: 'Prix le Plus Bas',
        highestPrice: 'Prix le Plus Haut',
        totalValue: 'Valeur Total',
        moreThan7Days: '7 à 14 Jours',
        moreThan14Days: '15 à 30 Jours',
        moreThan30Days: '31 à 60 Jours',
        moreThan60Days: 'Plus de 60 Jours',
        days: 'Jours',
        unknown: 'Inconnu',
    },
    latestSaleState: {
        delivered: 'Livré',
        deposit: 'Dépôt',
        sold: 'Vendu',
        test_drive: 'Essai routier',
        visited: 'Visité',
        appointment: 'Rendez-vous',
        new: 'Nouveau lead',
    },
    status: {
        active: 'Actif',
        invalid: 'Invalide',
        duplicate: 'Dupliqué',
        lost: 'Perdu',
    },
    division: {
        all: 'Tous',
        new: 'Neuf',
        used: 'Occasion',
    },
    modal: {
        areYouSure: 'Êtes-vous sûr de vouloir sélectionner cette unité d\'inventaire. \n {0} {1} #{2}?',
    },
    orderBy: {
        ascending: 'Croissant',
        descending: 'Décroissant',
    },
};
