<template>
    <div class="h-full min-h-screen flex flex-col" key="content" v-if="contentLoaded">
        <!-- Banners -->
        <status-banner />
        <announcement-banner />
        <impersonation-banner />
        <nylas-transfer-banner v-if="nylasV3enabled" />

        <div class="flex-1 relative flex">
            <template v-if="navIsEnabled">
                <expandable-main-nav-container />
            </template>

            <main
                class="flex-1 flex flex-col w-0 | transition-spacing duration-300"
                :class="{
                    'max-h-screen': $route.meta.fixedContent,
                    'ml-20': navIsEnabled && !isTouchDevice,
                }"
            >
                <main-header v-if="includeInLayout('header')" />
                <div
                    class="flex-1 flex | transition-spacing duration-300"
                    :class="sidebar.opened && sidebar.push ? 'md:mr-80 xl:mr-96 2xl:mr-128' : 'mr-0'"
                >
                    <portal-target
                        :style="leftSidebarStyle"
                        class="sticky left-0 top-0"
                        slim
                        name="left-sidebar"
                    />
                    <div
                        class="relative flex-1 max-w-full flex flex-col p-3 | md:p-6 | print:p-0"
                        v-show="!contextLoading"
                    >
                        <content-header v-if="includeInLayout('contentHeader')" />
                        <router-view :right-sidebar-opened="sidebar.opened" class="relative flex-1" />
                        <portal-target name="content-footer" slim />

                        <div class="sticky inset-x-0 bottom-6 flex z-30 pointer-events-none">
                            <portal-target name="anchor-bottom-left" class="pointer-events-auto" slim />
                            <portal-target name="anchor-bottom-right" class="ml-auto pointer-events-auto" slim />

                            <div class="absolute right-0 bottom-0 flex items-end justify-end gap-6">
                                <slide-over type="addLead" />
                                <slide-over type="interAccountLeads" />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>

        <!-- Functional components -->
        <listen-to-broadcasting />

        <!-- Screen Pops -->
        <screen-pop v-if="!$browser.isMobileWebView()" />
        <screen-pop-video-conference v-if="!$browser.isMobileWebView()" />

        <!-- Modals -->
        <sms-lead />
        <confidentiality-agreement />
        <modal-event />
        <call-summary />
        <activix-confirm-modal
            portal="modal-event-sub"
            :content="$t('modal.createAppointmentConfirmMessage')"
            :opened.sync="createAppointmentModal.opened"
            :title="$t('modal.createAppointment')"
            @approve="openAppointmentModal"
        />
    </div>
</template>

<script>
// eslint-disable-next-line @typescript-eslint/no-unused-vars
    import { mapActions, mapState } from 'pinia';
    import { isTouchDevice } from '@/utils/device.js';
    import { isValidNumber } from '@/utils/numbers.js';
    import IntercomClient from '@/plugins/intercom.js';

    import TaskEventType from '@/entities/TaskEventType.js';
    import FreshdeskUrl from '@/mixins/FreshdeskUrl.js';

    // Components
    import AnnouncementBanner from '@/components/AnnouncementBanner.vue';
    import ConfidentialityAgreement from '@/components/modals/ConfidentialityAgreement.vue';
    import ContentHeader from '@/components/ContentHeader.vue';
    import ExpandableMainNavContainer from '@/components/ExpandableMainNavContainer.vue';
    import ListenToBroadcasting from '@/components/utils/ListenToBroadcasting.js';
    import MainHeader from '@/components/MainHeader.vue';
    import ModalEvent from '@/components/modals/ModalEvent.vue';
    import ScreenPop from '@/components/modals/ScreenPop.vue';
    import ScreenPopVideoConference from '@/components/modals/ScreenPopVideoConference.vue';
    import SmsLead from '@/components/modals/SmsLead.vue';
    import StatusBanner from '@/components/StatusBanner.vue';
    import ImpersonationBanner from '@/components/ImpersonationBanner.vue';
    import NylasTransferBanner from '@/components/NylasTransferBanner.vue';
    import SlideOver from '@/components/SlideOver.vue';

    // Pinia
    import { useContextStore } from '@/store/modules/context/store.js';
    import { useLayoutStore } from '@/store/modules/layout/store.js';
    import { useGlobalStore } from '@/store/store.js';
    import CallSummary from '@/components/modals/CallSummary.vue';

    export default {
        components: {
            CallSummary,
            SlideOver,
            AnnouncementBanner,
            ConfidentialityAgreement,
            ContentHeader,
            ExpandableMainNavContainer,
            ListenToBroadcasting,
            MainHeader,
            ModalEvent,
            ScreenPop,
            ScreenPopVideoConference,
            SmsLead,
            StatusBanner,
            ImpersonationBanner,
            NylasTransferBanner,
        },

        mixins: [FreshdeskUrl],

        metaInfo() {
            return {
                title: this.$route.name ? this.$t(`titles.${this.$route.name}`) : '',
            };
        },

        props: {
            showLoading: {
                type: Boolean,
                require: true,
            },
            contextLoading: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                loaded: false,
                createAppointmentModal: {
                    opened: false,
                    payload: {},
                },
                sidebar: {
                    opened: false,
                    push: false,
                },
                isTouchDevice: isTouchDevice(),
            };
        },

        computed: {
            ...mapState(useLayoutStore, ['navExpanded', 'bodySpacingTop', 'headerHeight']),
            ...mapState(useGlobalStore, ['authUser', 'parentAuthUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextUser: 'user',
                contextGroup: 'group',
            }),

            navIsEnabled() {
                return this.includeInLayout('nav') && !this.$browser.isMobileWebView();
            },

            contentLoaded() {
                return !!this.loaded && this.$route.meta.auth !== false && !!this.authUser.id;
            },

            contextQuery() {
                const accountId = this.canSaveInUrl('accountId') ? this.contextAccount.id : null;
                const groupId = this.canSaveInUrl('groupId') ? this.contextGroup.id : null;
                const userId = this.canSaveInUrl('userId') ? this.contextUser.id : null;

                return {
                    accountId,
                    groupId,
                    userId,
                };
            },

            leftSidebarStyle() {
                const top = this.headerHeight + this.bodySpacingTop;

                return {
                    top: `${top}px`,
                    height: `calc(100vh - ${top}px)`,
                };
            },

            nylasV3enabled() {
                return this.$feature.isEnabled('nylas-v3');
            },
        },

        watch: {
            '$route.name': {
                immediate: true,
                handler() {
                    this.verifyAccess();
                    IntercomClient.ping();
                },
            },

            contentLoaded: {
                immediate: true,
                async handler(contentLoaded) {
                    this.$emit('update:showLoading', !contentLoaded);

                    if (contentLoaded) {
                        await this.$nextTick();
                        IntercomClient.init(this.authUser);
                    }
                },
            },

            contextQuery(newContextQuery) {
                this.updateQuery(newContextQuery);
            },

            'contextAccount.id': {
                immediate: true,
                handler(newAccountId, oldAccountId) {
                    if (!newAccountId || newAccountId == oldAccountId) {
                        return;
                    }

                    this.verifyAccess();

                    this.$broadcasting.accountChanged(newAccountId, oldAccountId);
                },
            },

            'authUser.confidentiality_agreement'() {
                IntercomClient.init(this.authUser);
            },

            'authUser.id': {
                immediate: true,
                handler(user) {
                    this.fetchAccounts(user.accountId);
                },
            },
        },

        methods: {
            ...mapActions(useLayoutStore, ['setBodyMarginTop', 'setBodyPaddingTop']),
            ...mapActions(useContextStore, [
                'setContextDivisionId',
                'setContextTeamId',
                'setContextAccountAction',
                'setContextGroupAction',
                'setContextLeadIdAction',
                'setContextUserAction',
            ]),
            ...mapActions(useGlobalStore, [
                'setGroups',
                'fetchAccounts',
            ]),

            async loadInitialData() {
                await Promise.all([this.fetchGroups()]);
                this.$api.authUser.triggerScreenPop();
            },

            async fetchGroups() {
                const groups = await this.$api.groups.index();

                this.setGroups(groups);
            },

            async setContextData() {
                const query = this.getUrlQuery();
                const storage = this.$ls.get('context', {});

                const context = {
                    accountId: isValidNumber(query.accountId) ? query.accountId : storage.accountId,
                    groupId: isValidNumber(query.groupId) ? query.groupId : storage.groupId,
                    userId: isValidNumber(query.userId) ? query.userId : storage.userId,
                };

                this.setContextLeadIdAction(null);
                this.setContextDivisionId(storage.divisionId);
                this.setContextTeamId(storage.teamId);

                if (this.$route.name == 'leads.update') {
                    return;
                }

                this.$wait.start('fetching.contextData');

                await this.setContextGroupAction(context.groupId);

                if (!this.contextGroup.id) {
                    await this.setContextUserAction(context.userId);
                }

                if (!this.contextAccount.id && this.$route.name != 'accounts.update') {
                    if (this.parentAuthUser.default_account_id && !isValidNumber(query.accountId)) {
                        context.accountId = this.parentAuthUser.default_account_id;
                    }

                    await this.setContextAccountAction(context.accountId);
                }

                this.$wait.end('fetching.contextData');
            },

            openAppointmentModal() {
                this.$eventBus.$emit('open-add-task-event', {
                    leadId: this.createAppointmentModal.payload.leadId,
                    title: this.createAppointmentModal.payload.title,
                    description: this.createAppointmentModal.payload.description,
                    guests: this.createAppointmentModal.payload.guests,
                    priority: this.createAppointmentModal.payload.priority,
                    user: this.createAppointmentModal.payload.user_id,
                    reminders: this.createAppointmentModal.payload.reminders,
                    taskEventType: TaskEventType.APPOINTMENT,
                });
            },

            verifyAccess() {
                if (!this.$route.name || this.$route.name == 'auth.login') {
                    return;
                }

                if (this.$route.name === 'analytics') {
                    if (!this.parentAuthUser.hasAccessTo(this.$route.name, this.contextAccount.id)) {
                        this.$router.replace({ name: 'unauthorized' });
                    }

                    return;
                }

                if (!this.authUser.hasAccessTo(this.$route.name, this.contextAccount.id)) {
                    this.$router.replace({ name: 'unauthorized' });
                }
            },

            getUrlQuery() {
                if (!this.$route.query) {
                    return {};
                }

                const query = {};

                Object.keys(this.$route.query).forEach(key => {
                    if (this.canSaveInUrl(key)) {
                        query[key] = this.$route.query[key];
                    }
                });

                return query;
            },

            canSaveInUrl(key) {
                const saveInUrl = this.$route.meta.saveInUrl || [];

                return saveInUrl.includes(key);
            },

            openCreateAppointmentModal(payload) {
                this.createAppointmentModal.opened = true;
                this.createAppointmentModal.payload = payload;
            },

            async init() {
                await Promise.all([this.loadInitialData(), this.setContextData()]);

                this.loaded = true;
            },

            getRedirect() {
                return this.getReferer();
            },

            getReferer() {
                const referer = this.$ls.get('referer');

                this.$ls.remove('referer');

                return referer;
            },

            onBodyMutation(mutations) {
                mutations.forEach(mutation => {
                    this.setBodyMarginTop(mutation.target.style.marginTop);
                    this.setBodyPaddingTop(mutation.target.style.paddingTop);
                });
            },
        },

        created() {
            const redirect = this.getRedirect();

            if (redirect === 'freshdesk') {
                this.openFreshdesk(false);
            } else {
                this.init();
            }

            this.bodyObserver = new MutationObserver(this.onBodyMutation);
            this.bodyObserver.observe(document.body, { attributeFilter: ['style'] });

            this.$eventBus.$on('open-modal-event-appointment', this.openCreateAppointmentModal);
            this.$eventBus.$on('load-initial-data', this.loadInitialData);
        },

        beforeDestroy() {
            this.$eventBus.$off('open-modal-event-appointment', this.openCreateAppointmentModal);
            this.$eventBus.$off('load-initial-data', this.loadInitialData);

            this.bodyObserver.disconnect();
            this.bodyObserver = null;
        },
    };
</script>
