import Vue, { reactive } from 'vue';
import { loadScript } from 'vue-plugin-load-script';

import i18n from './vue-i18n.js';
import behavior from './mixpanel.js';

const setZiggeoLocale = () => {
    ZiggeoApi.V2.Locale.mediaLocale.register({
        'ba-videorecorder-chooser.record-video': i18n.t('communications.video.ziggeoTranslations.record'),
        'ba-videorecorder-chooser.upload-video': i18n.t('communications.video.ziggeoTranslations.upload'),
    }, [`language:${i18n.locale}`], 10);

    ZiggeoApi.V2.Locale.setLocale(i18n.locale || 'en');
};

const API_KEY = process.env.VUE_APP_ZIGGEO_API_KEY;
const AUTH_TOKEN = process.env.VUE_APP_ZIGGEO_AUTH_TOKEN;

class Ziggeo {
    ziggeoApp = null;

    data = reactive({ videos: [] });

    async init() {
        if (!API_KEY || this.ziggeoApp) {
            return;
        }

        if (!window.ZiggeoApi) {
            await loadScript('https://assets.ziggeo.com/v2-r38/ziggeo.js');
        }

        if (!window.ZiggeoApi) {
            return;
        }

        this.ziggeoApp = new window.ZiggeoApi.V2.Application({
            token: API_KEY,
            webrtc_on_mobile: true,
            auth: true,
        });
    }

    getVideoFile(videoId = '') {
        return `https://embed-cdn.ziggeo.com/v1/applications/${API_KEY}/videos/${videoId}/video.mp4`;
    }

    async getVideoStateFromZiggeo(videoId = '') {
        await this.init();

        if (!this.ziggeoApp) {
            return null;
        }

        return this.ziggeoApp.videos.get(videoId, { server_auth: AUTH_TOKEN });
    }

    async fetchVideoState(id, video) {
        if (!video.ziggeoId) {
            return;
        }

        await this.init();

        if (!window.ZiggeoApi) {
            return;
        }

        const sameZiggeoIdVideo = this.getSameZiggeoIdVideo(video);

        if (sameZiggeoIdVideo) {
            return;
        }

        let status = '';
        let ziggeoId = video.ziggeoId;

        const response = await this.getVideoStateFromZiggeo(video.ziggeoId);

        if (!response) {
            return;
        }

        response.success((data) => {
            switch (data.state_string) {
                case 'READY':
                    status = 'processed';
                    break;

                case 'FAILED':
                case 'EMPTY':
                    status = '';
                    ziggeoId = '';
                    break;
            }

            this.updateVideo(id, { status, ziggeoId });
        });

        response.error(() => {
            this.resetVideo(id);
        });
    }

    mountRecorder(id, element) {
        const video = this.getVideoById(id);

        if (!video || !video.embedding) {
            return;
        }

        video.embedding.reset();

        element.appendChild(video.node);

        this.fetchVideoState(id, video);
    }

    async createRecorder(id, element, ziggeoId, user) {
        const getVideo = this.getVideoById(id) || null;

        if (!empty(getVideo) && !['', 'sent', 'readyToRecord', 'recordingStopped'].includes(getVideo.status)) {
            return;
        }

        await this.init();

        if (!window.ZiggeoApi) {
            return;
        }

        setZiggeoLocale();

        const root = document.createElement('div');

        if (element && !element.querySelector('.ba-videorecorder-container')) {
            element.appendChild(root);
        }

        const recorder = new ZiggeoApi.V2.Recorder({
            element: root,
            attrs: {
                'video-profile': '_activix',
                recordingwidth: '1080',
                recordingheight: '720',
                fittodimensions: true,
                allowcustomupload: false,
                theme: 'modern',
                'custom-data': {
                    account_sid: user.account.sid,
                    user_id: user.id,
                    lead_id: id,
                },
            },
        });

        const video = {
            ziggeoId: ziggeoId || '',
            status: '',
            progress: 0,
            embedding: recorder,
            node: root,
        };

        if (this.getVideoById(id)) {
            this.updateVideo(id, video);
        } else {
            this.setVideo(id, video);
        }

        recorder.activate();

        const onUnload = e => {
            const confirmationMessage = 'Your video is still uploading';

            (e || window.event).returnValue = confirmationMessage; // Gecko + IE
            return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
        };

        recorder.on('uploading', () => {
            behavior.track('VideoPresenting', { action: 'uploading' });
            this.updateVideo(id, { status: 'uploading', ziggeoId: recorder.get('video') });

            window.addEventListener('beforeunload', onUnload);
        });

        recorder.on('upload_progress', (kbSent, kbTotal) => {
            const progress = Math.round((kbSent / kbTotal) * 100);

            this.updateVideo(id, { progress });
        });

        recorder.on('ready_to_record', () => {
            behavior.track('VideoPresenting', { action: 'recording-ready' });
            this.updateVideo(id, { status: 'readyToRecord' });
        });

        recorder.on('recording_stopped', () => {
            behavior.track('VideoPresenting', { action: 'recording-stopped' });
            this.updateVideo(id, { status: 'recordingStopped' });
        });

        recorder.on('uploaded', () => {
            this.updateVideo(id, { status: 'uploaded', progress: 100 });
        });

        recorder.on('processed', () => {
            this.updateVideo(id, { status: 'processed' });
            behavior.track('VideoPresenting', { action: 'recording-processed' });
            Vue.notify.success({
                title: i18n.t('communications.video.statusMessage.successTitle'),
                text: i18n.t('communications.video.statusMessage.successText'),
            });

            window.removeEventListener('beforeunload', onUnload);
        });

        recorder.on('upload_selected', () => {
            // eslint-disable-next-line no-underscore-dangle
            if (!/^video\/\w{2,4}/.test(recorder._videoFile.type)) {
                recorder.reset();
                this.deleteVideo(id);
                behavior.track('VideoPresenting', { action: 'upload', file: 'bad' });
                Vue.notify.error(i18n.t('communications.video.statusMessage.wrongFileType'));
            }

            behavior.track('VideoPresenting', { action: 'upload', file: 'good' });
        });

        this.fetchVideoState(id, video);
    }

    updateVideo(id, { ...payload }) {
        const index = this.data.videos.findIndex(video => video.id === id);

        if (index === null) {
            return;
        }

        this.data.videos.splice(index, 1, { id, ...this.data.videos[index], ...payload });
    }

    getSameZiggeoIdVideo(video) {
        return this.data.videos.find(recorder => {
            return video.id !== recorder.id && video.ziggeoId === recorder.ziggeoId;
        });
    }

    getVideoById(id) {
        return this.data.videos.find(video => video.id === id) || null;
    }

    resetVideo(id) {
        const video = this.getVideoById(id);

        if (!video) {
            return;
        }

        if (video.embedding) {
            video.embedding.reset();
        }

        this.resetVideoZiggeo(id);
    }

    resetVideoZiggeo(id) {
        const index = this.data.videos.findIndex(video => video.id === id);

        if (index === null) {
            return;
        }

        this.data.videos.splice(index, 1, {
            id,
            status: '',
            ziggeoId: '',
            progress: 0,
            embedding: this.data.videos[index]?.embedding,
            node: this.data.videos[index].node,
        });
    }

    setVideo(id, payload) {
        if (this.data.videos.some(video => video.id === id)) {
            return;
        }

        this.data.videos.push({ id, ...payload });
    }

    async deleteVideo(id, ziggeoId = null) {
        await this.init();

        if (!this.ziggeoApp) {
            return;
        }

        if (ziggeoId) {
            behavior.track('VideoPresenting', { action: 'deleted' });
            await this.ziggeoApp.videos.destroy(ziggeoId, { server_auth: AUTH_TOKEN });
            Vue.notify.info(i18n.t('communications.video.isDeleted'));
        }

        this.resetVideoZiggeo(id);
    }

    async createLandingPage(id, userId, { accountSid, leadSid, title }) {
        const video = this.getVideoById(id);

        return Vue.axios.post(`${process.env.VUE_APP_VIDEO_SERVICE_URL}/api/video`, {
            ziggeo_id: video.ziggeoId,
            account_sid: accountSid,
            lead_sid: leadSid,
            title,
        });
    }

    async updateZiggeoVideo(ziggeoId, payload) {
        await this.init();

        if (!ziggeoId || !this.ziggeoApp) {
            return;
        }

        this.ziggeoApp.videos.update(ziggeoId, payload, { server_auth: AUTH_TOKEN });
    }
}

export default Ziggeo;
