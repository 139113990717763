import { useGlobalStore } from '@/store/store.js';
import { useAccountCardStore } from '@/store/modules/accountCard/store.js';
import { useAssociatedLeadsStore } from '@/store/modules/associatedLeads/store.js';
import { useCalendarStore } from '@/store/modules/calendar/store.js';
import { useClientCardStore } from '@/store/modules/clientCard/store.js';
import { useContextStore } from '@/store/modules/context/store.js';
import { useCustomerStore } from '@/store/modules/customer/store.js';
import { useDashboardStore } from '@/store/modules/dashboard/store.js';
import { useFlowStore } from '@/store/modules/flow/store.js';
import { useUserCardStore } from '@/store/modules/userCard/store.js';
import { useLayoutStore } from '@/store/modules/layout/store.js';
import { useLeadStore } from '@/store/modules/lead/store.js';
import { useImportStore } from '@/store/modules/imports/store.js';
import { useInterAccountLeadsStore } from '@/store/modules/interAccountLeads/store.js';

export function initStores() {
    useGlobalStore();
    useAccountCardStore();
    useAssociatedLeadsStore();
    useCalendarStore();
    useClientCardStore();
    useContextStore();
    useCustomerStore();
    useDashboardStore();
    useFlowStore();
    useImportStore();
    useInterAccountLeadsStore();
    useLayoutStore();
    useLeadStore();
    useUserCardStore();
}
